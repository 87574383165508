@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fff5ea;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', ' Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Dans votre fichier CSS personnalisé */
.custom-shadow {
  @apply shadow-custom;
}

/* Ajoutez cette règle en dehors de `@apply` */
@layer utilities {
  .shadow-custom {
    box-shadow: 0px 10px 20px -5px black;
  }
}

.custom-shadow2 {
  @apply shadow-custom2;
}

/* Ajoutez cette règle en dehors de `@apply` */
@layer utilities {
  .shadow-custom {
    box-shadow: 0px 10px 20px -5px black;
  }
  .shadow-custom2 {
    -webkit-box-shadow: 0px -6px 8px 7px white;
    box-shadow: 0px -1px 8px 4px rgba(255, 255, 255, 0.5);
  }
}

@keyframes bounceUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.animate-bounce-up-down {
  animation: bounceUpDown 1s infinite;
}
